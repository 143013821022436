import {
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  Select as MuiSelect,
} from "@mui/material";

const Select = ({
  id,
  name,
  label,
  value,
  error,
  disabled = false,
  onChange,
  displayedHelperText,
  options = [],
  allowNone = false,
  sx = {},
}) => {
  return (
    <FormControl sx={{ minWidth: 120, my: "16px", ...sx }} error={error}>
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <MuiSelect
        labelId={`${id}-label`}
        id={id}
        name={name}
        value={value}
        label={label}
        disabled={disabled}
        onChange={onChange}
        variant="outlined"
      >
        {allowNone && (
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </MuiSelect>
      <FormHelperText>{displayedHelperText}</FormHelperText>
    </FormControl>
  );
};

export default Select;
