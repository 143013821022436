import * as Yup from "yup";
import { isValidRut } from "./stringHelpers";

Yup.addMethod(Yup.string, "isRut", function (message) {
  return this.test("is-rut", message, function (value) {
    const { path, createError } = this;

    if (!value) return true;

    return (
      isValidRut(value) ||
      createError({ path, message: message || "RUT inválido" })
    );
  });
});

export default Yup;
