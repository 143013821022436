import { FormControl, Typography, FormHelperText, Grid } from "@mui/material";
import CustomRadio from "components/_MRui/inputs/CustomRadio";

const CustomRadioGroup = ({
  id,
  label,
  value,
  error,
  onChange,
  displayedHelperText,
  options = [],
  sx = {},
}) => {
  return (
    <FormControl sx={{ my: "16px", ...sx }} error={error}>
      <Typography id={`${id}-label`} sx={{ my: 1 }}>
        {label}
      </Typography>
      <Grid container spacing={2}>
        {options.map((option, index) => (
          <CustomRadio
            key={index}
            icon={option.icon}
            title={option.title}
            description={option.description || ""}
            selected={value === option.value}
            onClick={() => onChange({ target: { value: option.value } })}
          />
        ))}
      </Grid>
      <FormHelperText>{displayedHelperText}</FormHelperText>
    </FormControl>
  );
};

export default CustomRadioGroup;
