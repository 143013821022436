import {
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  Select as MuiSelect,
} from "@mui/material";

import { useState, useEffect } from "react";

const LazySelect = ({
  id,
  name,
  label,
  value,
  error,
  disabled = false,
  onChange,
  displayedHelperText,
  action,
  actionCallback,
  actionDefaultValue = "",
  actionDefaultLabel = "Por defecto",
  baseOptions = [],
  allowNone = false,
  sx = {},
}) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState(
    !actionDefaultValue
      ? [...baseOptions]
      : [
          ...baseOptions,
          { value: actionDefaultValue, label: actionDefaultLabel },
        ]
  );

  useEffect(() => {
    if (open && !loading && options.length <= 1) {
      setLoading(true);
      action().then((response) => {
        if (response && response.data) {
          setOptions([
            ...baseOptions,
            ...response.data.instances.map((instance) =>
              actionCallback(instance)
            ),
          ]);

          if (actionDefaultValue) {
            console.log({ actionDefaultValue });
            const defaultFound = response.data.instances.find(
              (instance) => instance._id === actionDefaultValue
            );
            if (defaultFound) {
              onChange({ target: { value: defaultFound._id } });
            }
          }
        }
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <FormControl sx={{ minWidth: 120, my: "16px", ...sx }} error={error}>
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <MuiSelect
        labelId={`${id}-label`}
        id={id}
        name={name}
        value={value}
        label={label}
        disabled={disabled}
        onChange={onChange}
        onOpen={() => setOpen(true)}
        variant="outlined"
      >
        {allowNone && (
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </MuiSelect>
      <FormHelperText>{displayedHelperText}</FormHelperText>
    </FormControl>
  );
};

export default LazySelect;
