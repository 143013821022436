import React, { useRef, useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { helper } from "utils";

const AddressAutocomplete = ({
  name,
  label,
  value = "",
  error,
  disabled,
  onChange,
  helperText,
  additionalProps,
}) => {
  const autocompleteRef = useRef(null);
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    helper.generateGoogleMapsAutocompleteEmbed(() => {
      if (autocompleteRef.current && window.google && window.google.maps) {
        const autocomplete = new window.google.maps.places.Autocomplete(
          autocompleteRef.current,
          {
            types: ["(regions)"],
            componentRestrictions: { country: "cl" },
          }
        );

        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();
          if (place.formatted_address) {
            const addressComponents = place.address_components;
            let commune = "";
            let region = "";
            let country = "";
            let city = "";

            addressComponents.forEach((component) => {
              const types = component.types;
              if (
                types.includes("sublocality_level_1") ||
                types.includes("locality")
              ) {
                commune = component.long_name;
              }
              if (types.includes("administrative_area_level_1")) {
                region = component.long_name;
              }
              if (types.includes("country")) {
                country = component.long_name;
              }
              if (types.includes("locality")) {
                city = component.long_name;
              }
            });
            setInputValue(place.formatted_address);
            onChange({
              target: {
                value: {
                  formatted_address: place.formatted_address,
                  "info.commune": commune,
                  "info.region": region,
                  "info.country": country,
                  "info.city": city,
                },
              },
            });
          }
        });
      }
    });
  }, [autocompleteRef]);

  const handleChange = (event) => {
    setInputValue(event.target.value);
    onChange(event);
  };

  return (
    <TextField
      fullWidth
      id={name}
      name={name}
      label={label}
      disabled={disabled}
      value={inputValue}
      onChange={handleChange}
      variant="outlined"
      inputRef={autocompleteRef}
      error={error}
      helperText={helperText}
      {...additionalProps}
    />
  );
};

export default AddressAutocomplete;
