import { toInteger } from "lodash";
import { validate, format } from "rut.js";

export const cleanRut = (rut) => {
  return format(rut, { dots: false });
};

export const formatRut = (rut) => {
  return format(rut);
};

export const isValidRut = (rut) => {
  return validate(rut);
};

export const formatPhone = (phone) => {
  return phone.replace(/[^0-9]/g, "");
};

export const formatCurrency = (value) => {
  const cleanValue = clearCurrency(value);
  const parts = cleanValue.toString().split(",");
  const integerPart = parts[0];
  const decimalPart = parts[1] ? `,${parts[1]}` : "";

  const formattedIntegerPart = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    "."
  );

  return `$${formattedIntegerPart}${decimalPart}`;
};

export const clearCurrency = (value) => {
  return toInteger(value.toString().replace(/[^0-9]/g, ""));
};
