import Box from "@mui/material/Box";
import FormField from "./FormField";

const FormBuilder = ({
  containerSx = {
    display: "grid",
    gridTemplateColumns: {
      xs: "1fr",
      sm: "repeat(2, 1fr)",
    },
    columnGap: 2,
  },
  fieldConfigs,
  formik,
  disabled = false,
}) => {
  if (!fieldConfigs || !formik) return null;
  return (
    <Box sx={containerSx}>
      {fieldConfigs.map((fieldConfig) => {
        if (fieldConfig.hidden) return null;
        return (
          <FormField
            key={fieldConfig.name}
            fieldConfig={{
              ...fieldConfig,
              disabled: disabled || fieldConfig.disabled,
            }}
            formik={formik}
          />
        );
      })}
    </Box>
  );
};

export default FormBuilder;
